import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  // NOTE: do not change the order or routes
  { path: 'm', redirectTo: 'm/demo', pathMatch: 'full' },
  {
    path: 'm',
    loadChildren: () => import('./modules/mobile/mobile.module').then(m => m.MobileModule),
  },

  { path: '', redirectTo: 'demo', pathMatch: 'full' },

  // NOTE: do not change the order or routes
  {
    path: '',
    loadChildren: () => import('./modules/desktop/desktop.module').then(m => m.DesktopModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
