import { BitfStore } from '@bitf/core/models/bitf-store.model';
import { User } from '@models';

// NOTE: default values must be defined in the StoreService, not in the model
// (otherwise the default here will override the super Object.assign())
export class Store extends BitfStore {
  user: User;
  prop1: any;

  constructor(storeData: Partial<Store>) {
    super(storeData);
  }
}
