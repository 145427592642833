import { IBitfApiCallState } from '@interfaces';
import { BitfApiCallStateMapper } from '@bitf/core/api-call-state/bitf-api-call-state.mapper';

export class DefaultApiCallStateMapper extends BitfApiCallStateMapper {
  buildApiRequest(apiCallState: IBitfApiCallState): void {
    apiCallState.apiRequest.queryParams = {};
    this.computedApiRequestParts.queryParams.forEach(query => {
      Object.assign(apiCallState.apiRequest.queryParams, query);
    });
    if (this.computedApiRequestParts.query[0] && this.computedApiRequestParts.query[0].firstName) {
      apiCallState.apiRequest.query = this.computedApiRequestParts.query[0];
    } else {
      apiCallState.apiRequest.query = undefined;
    }
  }
}
