import {
  BitfMockDbPathBuilder,
  BitfMockDbRequestMapper,
  BitfMockDbResponseMapper,
  BitfMockDbEnvelopeMapper,
} from '@bitf/core/parsers/mock-db-parser';

import { IBitfParserStrategy } from '@interfaces';

export const BITF_CONFIGS: any = {
  apiErrorsInterceptorService: { interceptHttpResponsesWithCode: [401, '4XX', '5XX'] },
  toastMessage: { durationMs: 5000 },
  parsers: {
    defaultParser: 'mockDbParser',
    parserStrategies: {
      mockDbParser: {
        pathBuilder: new BitfMockDbPathBuilder(),
        requestMapper: new BitfMockDbRequestMapper(),
        responseMapper: new BitfMockDbResponseMapper(),
        envelopeMapper: new BitfMockDbEnvelopeMapper(),
      } as IBitfParserStrategy,
    },
  },
};
